import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Registry = () => {
  const image = useImage()

  return (
    <Layout>
      <Seo title="Registry" />

      <div className="max-w-2xl pt-8 md:pt-16 p-4 mx-auto">
        <h1 className="md:pb-4 text-primary-500">Registry</h1>
        <p className="pb-4">
          Our number one wish is for you to make it to Sedona and celebrate for
          a couple days with us at Red Agave! If you want to treat us beyond
          that we have set up a Honeyfund for our honeymoon as our registry. As
          a couple, we've always valued experiences together more than anything
          else. Here you can contribute funds to our dream honeymoon so that we
          can go all out in paradise. We're headed to Maui, Hawaii for a week
          after the wedding to soak it all in (with a pina colada in hand!).
        </p>

        <a
          className="bg-primary-500 text-white py-2 px-6"
          target="blank"
          href="https://www.honeyfund.com/wedding/peterson-morris-04-26-2022"
        >
          Honeyfund link
        </a>

        <div className="my-8">
          <Img fluid={image} />
        </div>
      </div>
    </Layout>
  )
}

const useImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "us/laughin-ring.jpeg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return data.image.childImageSharp.fluid
}

export default Registry
